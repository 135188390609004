<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-3>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Seções de Conteúdo - Edição</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'contentSections'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-content-section :contentSection="contentSection" :update="true"></form-content-section>
</span>
</template>

<script>
import FormContentSectionComponent from "./partial/FormContentSectionComponent";

export default {
    name: "EditContentSectionComponent",
    created() {
        this.loadContentSection()
    },
    props: {
        cont_sec_id: {
            require: true
        }
    },
    data() {
        return {
            contentSection: {
                cont_sec_id: '',
                language_id: '1',
                content_type_id: '',
                cont_sec_nome: '',
                cont_sec_slug: '',
                cont_sec_publicado: false,
                cont_sec_ordem: 0,
                cont_sec_imagem: '',
                cont_sec_descricao: '',
                cont_sec_status: false,
            },
        }
    },
    methods: {
        loadContentSection() {
            this.$store.dispatch('loadContentSection', this.cont_sec_id)
                .then(response => this.contentSection = response)
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {
        formContentSection: FormContentSectionComponent
    }
};
</script>

<style scoped>

</style>
